// function loadWijzigAfspraak() {
//
//     $(document).ready(function () {
//
//         /**
//          *    Zorgen dat de functie steeds weer opnieuw wordt aangeroepen
//          */
//
//         //$(".toggle-price").hide();
//         $('#gratis').click(function (event) {
//             $(".price-wrapper").toggle();
//             $('select[name=rekening]').parent().toggle();
//             if (!$(this).is(':checked')) {
//                 $('.link-toggle-price').click();
//             }
//         });
//
//         if ($('#vertrek_zones').val() == "0") {
//             $(".price-wrapper").toggle();
//             $('select[name=rekening]').parent().toggle();
//             $('#gratis').attr('checked', 'checked');
//         }
//
//         $('#zones_vertrek').show();
//         $('#zones_retour').show();
//
//         $('#vertrek_zones').show();
//         $('#retour_zones').show();
//
//         $('.link-toggle-price').click(function (event) {
//             determineDeparture();
//             determineRetour();
//             $(this).next('.link-toggle-price').addClass("active");
//             setTimeout(function () {
//                 $('.link-toggle-price').next('.toggle-price').slideDown(200);
//                 $('.link-toggle-price').contents('img.expander').attr({
//                     src: "",
//                     alt: ""
//                 });
//             }, 500);
//             $(this).contents('img.expander').attr({
//                 src: "assets/_img/refresh.gif",
//                 alt: "(Sluiten)"
//             });
//             //alert('on');
//         }, function (event) {
//         });
//
//
//         $(".showalternatief1").click(function () {
//             $(".alternatief1").slideToggle(200);
//         });
//         $(".showalternatief2").click(function () {
//             $(".alternatief2").slideToggle(200);
//         });
//         $(".showalternatief3").click(function () {
//             $(".alternatief3").slideToggle(200);
//         });
//
//         $(".showalternatief").click(function () {
//             $(this).next(".alternatief").slideToggle(200);
//         });
//
//         $(".input").autocomplete("./ajaxIndex.php?p=autocomplete_ajax", {autoFill: true, selectOnly: true});
//         $(".input_adressen").autocomplete("./ajaxIndex.php?p=autocomplete_ajax_adressen", {
//             autoFill: true,
//             selectOnly: true
//         });
//
//         $("#update_price").click(function () {
//             determineDeparture();
//             determineRetour();
//         });
//
//         //hide the all of the element with class msg_body
//         $(".info_body").hide();
//
//         $(".infobox").mouseover(function () {
//             $(".info_body").slideDown('fast');
//         });
//
//         $(".infobox").mouseout(function () {
//             $(".info_body").slideUp('fast');
//         });
//
//         //$('div.alternatief').hide();
//
//         $('div.toggle').hide();
//
//         $('.link-toggle').toggle(function (event) {
//             event.preventDefault();
//             $(this).next('.toggle').slideToggle(200);
//             $(this).next('.link-toggle').addClass("active");
//             $(this).contents('img.expander').attr({
//                 src: "assets/_img/close.png",
//                 alt: "(Sluiten)"
//             });
//             //alert('on');
//         }, function (event) { // same here
//             event.preventDefault();
//             $(this).next('.toggle').slideToggle(200);
//             $(this).contents('img.expander').attr({
//                 src: "assets/_img/open.png",
//                 alt: "(Open)"
//             });
//             ;
//         });
//
//         function determineDeparture() {
//             var vertrek = "";
//             var bestemming = "";
//             var wmo = $('#temp').val();
//             var jaar = $('#jaar').val();
//             //$('#vertrek_totaal').val($('#pr_woonplaats').val());
//             //$('#retour_totaal').val($('#pr_bs_woonplaats').val());
//
//
//             if ($('#vertrek_alt_checked').attr('checked') == false) {
//
//                 var vertrek = $('#pr_woonplaats').val();
//                 //alert(vertrek);
//             } else {
//                 var vertrek = $('#vertrek_alt_plaats').val();
//             }
//
//             if ($('#bestemming_alt_checked').attr('checked') == false) {
//                 var bestemming = $('#pr_bs_woonplaats').val();
//                 //alert(bestemming);
//             } else {
//                 var bestemming = $('#bestemming_alt_plaats').val();
//             }
//
//             jQuery.post('./ajaxIndex.php?p=define_price', {
//                 'v': vertrek,
//                 'b': bestemming,
//                 'wmo': wmo,
//                 'jaar': jaar
//             }, function (data) {
//                 var data3 = number_format($('#aantal_p').val() * $.trim(data), 2, ',', '.');
//                 $('#vertrek_totaal').val(data3);
//                 $('#vertrek_totaal_temp').val($('#aantal_p').val() * $.trim(data));
//                 $("#vertrek_plaatsen").val(vertrek + ' ' + bestemming + ' ' + jaar + ' ' + wmo);
//                 if (data == 0) {
//                     //alert("bla");
//                     $('#vertrek_prijs').val(1);
//                     $('#vertrek_totaal').attr('disabled', '');
//                     $('#vertrek_zones').show();
//                     $('#zones_vertrek').show();
//                 } else {
//                     //alert("bla1");
//                     $('#vertrek_prijs').val(0);
//                     $('#vertrek_totaal').attr('disabled', 'disabled');
//                     // $('#vertrek_zones').hide();
//                     // $('#zones_vertrek').hide();
//                 }
//                 jQuery.post('./ajaxIndex.php?p=define_zones', {
//                     'v': vertrek,
//                     'b': bestemming,
//                     'wmo': wmo,
//                     'jaar': jaar
//                 }, function (data) {
//                     $('#vertrek_zones').val($.trim(data));
//                 });
//
//                 //$('#vertrek_totaal_temp').val('-'+$.trim(data)+'');
//             });
//
//         }
//
//         function determineRetour() {
//             var vertrek = "";
//             var bestemming = "";
//             var wmo = $('#temp').val();
//             var jaar = $('#jaar').val();
//
//             if ($('#bestemming_alt_checked').attr('checked') == false) {
//                 var vertrek = $('#pr_bs_woonplaats').val();
//             } else {
//                 var vertrek = $('#bestemming_alt_plaats').val();
//             }
//
//             if ($('#alt_retour_checked').attr('checked') == false) {
//
//                 var bestemming = $('#pr_woonplaats').val();
//             } else {
//                 var bestemming = $('#alt_retour_plaats').val();
//             }
//
//             jQuery.post('./ajaxIndex.php?p=define_price', {
//                 'v': vertrek,
//                 'b': bestemming,
//                 'wmo': wmo,
//                 'jaar': jaar
//             }, function (data) {
//
//                 var data2 = number_format($('#aantal_p').val() * $.trim(data), 2, ',', '.');
//                 $('#retour_totaal').val(data2);
//                 $('#retour_totaal_temp').val($('#aantal_p').val() * $.trim(data));
//                 if (data == 0) {
//                     $('#retour_prijs').val(1);
//                     $('#retour_totaal').attr('disabled', '');
//                     $('#retour_zones').show();
//                     $('#zones_retour').show();
//                 } else {
//                     $('#retour_prijs').val(0);
//                     $('#retour_totaal').attr('disabled', 'disabled');
//                     // $('#retour_zones').hide();
//                     // $('#zones_retour').hide();
//                 }
//                 //$('#retour_totaal_temp').val('-'+data+'-');
//                 jQuery.post('./ajaxIndex.php?p=define_zones', {
//                     'v': vertrek,
//                     'b': bestemming,
//                     'wmo': wmo,
//                     'jaar': jaar
//                 }, function (data) {
//                     $('#retour_zones').val($.trim(data));
//                 });
//             });
//
//         }
//
//         $('#vertrek_totaal').keyup(function () {
//             $('#vertrek_totaal_temp').val($('#vertrek_totaal').val());
//         });
//         $('#retour_totaal').keyup(function () {
//             $('#retour_totaal_temp').val($('#retour_totaal').val());
//         });
//         //Numeric restrictie
//
//         $('#vertrek_prijs').change(function () {
//             if ($('#vertrek_prijs').val() == 1) {
//                 $('#vertrek_totaal').val('');
//                 $('#vertrek_totaal').attr('disabled', '');
//                 $('#vertrek_zones').show();
//                 $('#zones_vertrek').show();
//             } else {
//                 determineDeparture();
//                 // $('#vertrek_totaal').attr('disabled', 'disabled');
//                 // $('#vertrek_zones').hide();
//                 // $('#zones_vertrek').hide();
//             }
//         });
//
//
//         $('#retour_prijs').change(function () {
//             if ($('#retour_prijs').val() == 1) {
//                 $('#retour_totaal').val('');
//                 $('#retour_totaal').attr('disabled', '');
//                 $('#retour_zones').show();
//                 $('#zones_retour').show();
//             } else {
//                 determineRetour();
//                 // $('#retour_totaal').attr('disabled', 'disabled');
//                 // $('#retour_zones').hide();
//                 // $('#zones_retour').hide();
//             }
//         });
//
//         $('#aantal_p').change(function () {
//             determineDeparture();
//             determineRetour();
//         });
//
//         $('#klantnaam').keyup(function () {
//             determineDeparture();
//             determineRetour();
//
//         });
//
//
//         /*	$('.links').hover(function(){
//                 determineDeparture();
//                 determineRetour();
//                 fill_vertrek();
//             });
//
//             $('.midden').hover(function(){
//                 determineDeparture();
//                 determineRetour();
//                 fill_vertrek();
//             });
//     /*
//             $('input').hover(function(){
//                 determineDeparture();
//                 determineRetour();
//                 fill_vertrek();
//             });
//     */
//         /*	$(":input").hover(function(){
//             determineDeparture();
//             determineRetour();
//         });*/
//
//         $('#vertrek_alt_plaats').keyup(function () {
//             determineDeparture();
//             determineRetour();
//         });
//
//         $('#bestemming_alt_plaats').keyup(function () {
//             determineDeparture();
//             determineRetour();
//         });
//
//     });
//
// // Vertrek punt suggestbox
//     function lookup_vertrek(inputString) {
//         if (inputString.length == 0) {
//             // Hide the suggestion box.
//             $('#suggestions_vertrek').hide();
//         } else {
//             $.post("./ajaxIndex.php?p=ajax_vertrek", {queryString: "" + inputString + ""}, function (data) {
//                 if (data.length > 0) {
//                     $('#suggestions_vertrek').show();
//                     $('#autoSuggestList_vertrek').html(data);
//                 }
//             });
//         }
//     } // lookup
//     //
//     // function fill_vertrek(title, id, adres, woonplaats, wmo) {
//     //     $('#klantnaam').val(title);
//     //     $('#haalId').val(id);
//     //     $('#martijn').val(id);
//     //     $('#pr_adres').val(adres);
//     //     $('#pr_woonplaats').val(woonplaats);
//     //     /*$('#wmo').val(wmo);
//     //
//     //         if(wmo != '') {
//     //             $('#temp').val('Ja');
//     //             displayPers();
//     //         } else {
//     //             $('#temp').val('Nee');
//     //             displayPers();
//     //         }
//     // */
//     //     setTimeout("$('#suggestions_vertrek').hide();", 200);
//     // }
//
// // Bestemming punt suggestbox
// //     function wijzigLookup_bestemming(inputString) {
// //         if (inputString.length == 0) {
// //             // Hide the suggestion box.
// //             $('#suggestions_bestemming').hide();
// //         } else {
// //             $.post("./ajaxIndex.php?p=ajax_bestemming", {queryString: "" + inputString + ""}, function (data) {
// //                 if (data.length > 0) {
// //                     $('#suggestions_bestemming').show();
// //                     $('#autoSuggestList_bestemming').html(data);
// //                 }
// //             });
// //         }
// //     } // lookup
//
//     // function fill_bestemming(title, id, adres, woonplaats, wmo) {
//     //     $('#bestemmingnaam').val(title);
//     //     $('#brengId').val(id);
//     //     $('#martijn2').val(id);
//     //     $('#pr_bs_adres').val(adres);
//     //     $('#pr_bs_woonplaats').val(woonplaats);
//     //
//     //     setTimeout("$('#suggestions_bestemming').hide();", 200);
//     // }
//
//
//     function checkform() {
//         //var valid = true;
//         //alert(document.findfield.bestemming_alt_adres.value);
//         var fout = "";
//
//         if (document.findfield.haalId.value == "") {
//             fout += ("U dient een klant op gegeven.\n");
//         }
//
//         if (document.findfield.vertrek_alt_checked.checked == true) {
//             if (document.findfield.vertrek_alt_plaats.value == "") {
//                 fout += ("Geen alternatieve plaats ingevuld bij vertrek.\n");
//                 //alert("Geen alternatieve plaats ingevuld bij vertrek");
//             }
//         }
//
//         if (document.findfield.bestemming_alt_checked.checked == true) {
//             if (document.findfield.bestemming_alt_plaats.value == "") {
//                 fout += ("Geen alternatieve plaats ingevuld bij bestemming.\n");
//                 //alert("Geen alternatieve plaats ingevuld bij bestemming");
//             }
//         }
//
//         if (document.findfield.alt_retour_checked.checked == true) {
//             if (document.findfield.alt_retour_plaats.value == "") {
//                 fout += ("Geen alternatieve plaats ingevuld bij alternatieve retour.\n");
//                 //alert("Geen alternatieve plaats ingevuld bij alternatieve retour");
//             }
//         }
//
//         if (document.findfield.brengId.value == "" && document.findfield.bestemming_alt_checked.checked == false || document.findfield.bestemming_alt_adres.value == "" && document.findfield.brengId.value == "") {
//             fout += ("U dient nog een bestemming op te geven.\n");
//         }
//
//         if (document.findfield.vertrek_datum.value == document.findfield.bestemming_datum.value) {
//             if (document.findfield.vertrek_tijd.value > document.findfield.bestemming_tijd.value && document.findfield.bestemming_tijd.value != "14:14" && document.findfield.bestemming_tijd.value != "12:12") {
//                 fout += ("Het is niet mogelijk de aankomsttijd eerder te plannen dan de vertrektijd.\n");
//             }
//
//             if (document.findfield.vertrek_tijd.value == document.findfield.bestemming_tijd.value) {
//                 fout += ("U dient de aankomsttijd later in te plannen dan de vertrektijd.\n");
//             }
//         }
//
//         if (fout == "") {
//             return true;
//         } else {
//             fout += ("\nOm de afspraak met succes te verwerken, gelieve bovenstaande punt(en) aan te passen.");
//             alert(fout);
//             return false;
//         }
//     }
//
//
//     function updateTotal() {
//         var totalVal = $('#totaal').val();
//         var totalVal1 = $('#totaal1').val();
//         var aantalP = $('#aantal_p').val();
//         var prijsVal = $('#vertrek_prijs').val();
//         var prijsVal_retour = $('#retour_prijs').val();
//
//         $('#totaal').val(number_format(aantalP * prijsVal, 2, ',', ''));
//         $('#totaal1').val(number_format(aantalP * prijsVal_retour, 2, ',', ''));
//     }
//
//     var options = {
//         script: "./ajaxIndex.php?p=z_haal&json=true&",
//         varname: "input",
//         json: true,
//
//         callback: function (obj) {
//             document.getElementById('wmo').value = obj.wmo;
//             document.getElementById('haalId').value = obj.id;
//
//             if (document.getElementById('wmo').value != '') {
//                 document.getElementById('temp').value = 'Ja';
//                 displayPrijs();
//             } else {
//                 document.getElementById('temp').value = 'Nee';
//                 displayPrijs();
//             }
//
//         }
//     };
//
//     /*
//     var as_json = new AutoSuggest('testinput', options);
//     var options_xml = { script:"ajaxIndex.php?p=z_haal", varname:"input" };
//     var as_xml = new AutoSuggest('testinput_xml', options_xml);
//     */
//     function displayPrijs() {
//         var varhtml = "";
//         $.get("./ajaxIndex.php?p=ajax_prijzen", {temp: $("#temp").attr("value")}, function (data) {
//             varhtml += data;
//             $("#vertrek_prijs").html(varhtml);
//             $("#retour_prijs").html(varhtml);
//             succes: updateTotal();
//         });
//
//     }
//
//     function displayPers() {
//         var varhtml = "";
//         $.get("./ajaxIndex.php?p=ajax_personen", {temp: $("#wmo_qeury").attr("value")}, function (data) {
//             varhtml += data;
//             $("#aantal_p").html(varhtml);
//             succes: updateTotal();
//         });
//
//     }
// }