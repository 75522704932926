$(document).ready(function() {

    $(".context").contextMenu({
            menu: 'myMenu'
        },
        function(action, el, pos) {
            var longstring = $(el).attr('id');
            var brokenstring = longstring.split("/");

            if (action == 'edit') {
                window.location = 'index.php?p=wijzig_afspraak&id=' + brokenstring[0] + '&rit=' + brokenstring[1];
            }
            if (action == 'copy') {
                tb_show('Afspraak kopieren', 'ajaxIndex.php?p=kopieer_afspraak&id=' + brokenstring[0] + '&rit=' + brokenstring[1] + '&height=310&width=400&KeepThis=true&TB_iframe=true', null);
            }
            if (action == 'delete') {
                tb_show('Afspraak verwijderen', 'ajaxIndex.php?p=verwijder_afspraak&id=' + brokenstring[0] + '&rit=' + brokenstring[1] + '&height=100&width=300', null);
            }
        });

    /* Developer */
    $(".todo").hide();
    $("#todo_collapse").click(function() {
        $(".todo").slideToggle("slow");
    });
    /* -- Developer --*/


    $("#meldingNOK").fadeOut(6000, function() {
        $(this).remove();
    });

    $("#meldingOK").fadeOut(6000, function() {
        $(this).remove();
    });


    $('.info_box').scrollFollow({
        speed: 500,
        offset: 20,
    });

    $('A[rel="external"]').click(function() {
        window.open($(this).attr('href'));
        return false;
    });

    //hide the all of the element with class msg_body
    $(".msg_body").hide();

    //toggle the componenet with class msg_body
    $(".toggle").click(function() {
        $(this).next(".msg_body").slideToggle(500);
    });

    $(".prullenbak_collapse").click(function() {
        $(this).next(".msg_body1").slideToggle(200);
    });

    $(".showalternatif").click(function() {
        $(this).next(".adres").slideToggle(200);
    });

    $(".contextmenu").click(function() {
        $(this).next(".contextmenu1").toggle(1);
    }, function() {
        $(this).next(".contextmenu1").toggle(0);
    });

    $(".close").click(function() {
        $(".msg_body").slideToggle();
    });

    //Rechtermuis klik uitzetten
    $("#outer-wrapper").bind("contextmenu", function(e) {
        return true;
    });


    $(".opties").showMenu({
        query: "#myMenu"

    });


    function ajax_update() {
        var wrapperId = '#laatste_actie';
        var postFile = 'http://web.wagena.com/klanten/belbus/_inc/Ajax_laatste_actie.php';
        $.post(postFile, function(data) { $(wrapperId).load('2000', function() { $(this).html(data)(); }).html(); });
        setTimeout('ajax_update()', 1000);

    }

    $("p").hover(function() {
        $(this).css({ "background-color": "yellow", "font-weight": "bolder" });
    }, function() {
        var cssObj = {
            "background-color": "#ddd",
            "font-weight": "",
            color: "rgb(0,40,244)"
        }
        $(this).css(cssObj);
    });

    $(function() {

        $(".opties, #fancy2").tooltip({
            track: true,
            fade: 100,
            delay: 0,
            showURL: false,
            fixPNG: true,
            showBody: " - ",
            extraClass: "pretty fancy",
            top: -30,
            left: -40
        });

    });

    $(function() {

        // Datepicker
        $('.datepicker').datepicker({
            inline: true
        });

    });

    $(".pauzedelete").click(function() {
        $(".pauze").fadeOut(2000, function() {
            $(this).remove(
                function() {
                    alert('fade done - click again!');
                });
        });
    });

    $(".editable_textarea").editable("_inc/beheer_ritcodes_save.php", {
        indicator: "saving...",
        submitdata: { _method: "put" },
        submit: 'Opslaan',
        cancel: 'Annuleren',
    });
});